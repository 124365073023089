import { Fragment, useContext } from 'react';
import { ContentWrap, InnerWrap, HeadWrapper } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText, OptionalText } from 'components/Typography';
import CustomCheckBox from 'components/CustomCheckBox';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'hooks';

const what_prompted = {
    "question": "What prompted you to seek tax relief?",
    "type": "check",
    "possible_answers": [
        "Received IRS notice",
        "Garnishment, lien or levy",
        "Unpaid taxes",
        "Other"
    ],
    "answer": []
};

const optionList = [
    { value: 1, label: 'Received IRS notice', isActive: false },
    { value: 2, label: 'Garnishment, lien or levy', isActive: false },
    { value: 3, label: 'Unpaid taxes', isActive: false },
    { value: 4, label: 'Other', isActive: false }
];


function arrayRemove(arr, value) {
    return arr.filter((ele) => { return ele != value; });
}

const ButtonWrapper = styled.div`
    width: 115%;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

function Q2WhatPrompted(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    const [currentActive, setCurrentActive] = useLocalStorage('what-prompted-list', optionList);
    const [isNextDisabled, setIsNextDisabled] = useLocalStorage('what-prompted-list-button', true);
    const toggleCheckBox = (currentValue, e) => {

        if (e.target.checked &&
            !what_prompted.answer.some(i => i.includes(optionList[currentValue - 1].label))) {
            what_prompted.answer.push(optionList[currentValue - 1].label);
        }
        else if (!e.target.checked) {
            what_prompted.answer = arrayRemove(what_prompted.answer, optionList[currentValue - 1].label);
        }

        const tempArray = [];
        currentActive.forEach(item => {
            tempArray.push({
                value: item.value,
                label: item.label,
                isActive: item.value === currentValue ? !item.isActive : item.isActive
            });
        });
        if (tempArray.find((item) => item.isActive === true)) {
            setIsNextDisabled(false);
        }
        else {
            setIsNextDisabled(true);
        }
        setCurrentActive(tempArray);
    }

    const goNext = () => {
        what_prompted.answer = [];
        currentActive.forEach(item => {
            if (item.isActive === true) {
                what_prompted.answer.push(item.label);
            }
        });
        localStorage.setItem('what_prompted', JSON.stringify(what_prompted));
        const payload = {
            progress: 10, nextPath: paths.Q2UN_FILED_TAX_YEARS
        };
        actions.increment(payload);
        history.push(paths.Q2UN_FILED_TAX_YEARS);
    }

    const goBack = () => {
        const payload = {
            progress: 10, nextPath: paths.INTRO
        };
        actions.decrement(payload);
        history.push(paths.INTRO);
    }

    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        What prompted you to seek <span className="color-blue">tax relief?</span>
                    </PrimaryText>
                    <OptionalText>
                        (Select all that apply)
                    </OptionalText>
                </HeadWrapper>
                <CustomInnerWrap>
                    {
                        optionList.map((item, index) => {
                            return (
                                <CustomCheckBox key={item.value}
                                    isActive={currentActive[index].isActive}
                                    checked={currentActive[index].isActive}
                                    toggle={toggleCheckBox.bind(null, item.value)}>
                                    {item.label}
                                </CustomCheckBox>
                            )
                        })
                    }
                </CustomInnerWrap>

                <ButtonWrapper>
                    <LeadFormBack onClick={goBack}>
                        Back
                    </LeadFormBack>
                    <LeadFormNext disabled={isNextDisabled} onClick={goNext}>
                        Next
                    </LeadFormNext>
                </ButtonWrapper>
            </ContentWrap>
        </Fragment>
    );
}

const CustomInnerWrap = styled(InnerWrap)`
    margin-top: 0px;
    

    @media (min-width: 992px){
        margin-top: 30px;    
        min-height: 370px;    
    }
`;

export default Q2WhatPrompted;
