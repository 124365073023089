import { Fragment } from 'react';
import { ContentWrap, InnerWrap, HeadWrapper } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText, OptionalText } from 'components/Typography';
import CustomCheckBox from 'components/CustomCheckBox';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'hooks';
import { isMobile } from "react-device-detect";

const yearList = [
    { value: 1, label: '2022', isActive: false },
    { value: 2, label: '2021', isActive: false },
    { value: 3, label: '2020', isActive: false },
    { value: 4, label: '2019', isActive: false },
    { value: 5, label: '2018', isActive: false },
    { value: 8, label: '2017 or older', isActive: false },
    { value: 9, label: 'Not sure', isActive: false }
];

const unfiled_tax_years = {
    "question": "Which tax years are unfiled?",
    "type": "check",
    "possible_answers": [
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017 or older",
        "Not sure"
    ],
    "answer": []
};

function arrayRemove(arr, value) {
    return arr.filter((ele) => { return ele != value; });
}

function Q3UnFiledTaxYearsList(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    const [currentActive, setCurrentActive] = useLocalStorage('tyl', yearList);
    const [isNextDisabled, setIsNextDisabled] = useLocalStorage('tyl-button', true);
    const toggleCheckBox = (currentValue, e) => {

        if (e.target.checked &&
            !unfiled_tax_years.answer.some(i => i.includes(yearList[currentValue - 1].label))) {
            unfiled_tax_years.answer.push(yearList[currentValue - 1].label);
        }
        else if (!e.target.checked) {
            unfiled_tax_years.answer = arrayRemove(unfiled_tax_years.answer, yearList[currentValue - 1].label);
        }

        const tempArray = [];
        currentActive.forEach(item => {
            tempArray.push({
                value: item.value,
                label: item.label,
                isActive: item.value === currentValue ? !item.isActive : item.isActive
            });
        });
        if (tempArray.find((item) => item.isActive === true)) {
            setIsNextDisabled(false);
        }
        else {
            setIsNextDisabled(true);
        }
        setCurrentActive(tempArray);
    }

    const goNext = () => {
        localStorage.setItem('unfiled-list-visited', 'visited');
        unfiled_tax_years.answer = [];
        currentActive.forEach(item => {
            if (item.isActive === true) {
                unfiled_tax_years.answer.push(item.label);
            }
        });
        localStorage.setItem('unfiled_tax_years', JSON.stringify(unfiled_tax_years));
        const payload = {
            progress: 10, nextPath: paths.Q4TAX_DEBT
        };
        actions.increment(payload);
        history.push(paths.Q4TAX_DEBT);
    }

    const goBack = () => {
        const payload = {
            progress: 10, nextPath: paths.Q2UN_FILED_TAX_YEARS
        };
        actions.decrement(payload);
        history.push(paths.Q2UN_FILED_TAX_YEARS);
    }

    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        Which tax years are <span className="color-blue">unfiled?</span>
                    </PrimaryText>
                    <OptionalText>
                        (Select all that apply)
                    </OptionalText>
                </HeadWrapper>

                {isMobile ? <InnerWrap marginTop={isMobile ? `-35px` : `0px`}>
                    {
                        yearList.map((item, index) => {
                            return (
                                <CustomCheckBox key={item.value}
                                    isActive={currentActive[index].isActive}
                                    checked={currentActive[index].isActive}
                                    toggle={toggleCheckBox.bind(null, item.value)}>
                                    {item.label}
                                </CustomCheckBox>
                            )
                        })
                    }
                </InnerWrap> : <LargeScreen className='animate__animated animate__fadeIn'>
                    {
                        yearList.map((item, index) => {
                            if (item.value == 7) {
                                return (
                                    <CustomCheckBox key={item.value}
                                        isActive={currentActive[index].isActive}
                                        checked={currentActive[index].isActive}
                                        toggle={toggleCheckBox.bind(null, item.value)}>
                                        {item.label}
                                    </CustomCheckBox>
                                )
                            }
                            if (item.value % 2 === 1) {
                                return (
                                    <CustomCheckBox className='float-left' key={item.value}
                                        isActive={currentActive[index].isActive}
                                        checked={currentActive[index].isActive}
                                        toggle={toggleCheckBox.bind(null, item.value)}>
                                        {item.label}
                                    </CustomCheckBox>
                                )
                            }
                            else {
                                return (
                                    <CustomCheckBox className='float-right' key={item.value}
                                        isActive={currentActive[index].isActive}
                                        checked={currentActive[index].isActive}
                                        toggle={toggleCheckBox.bind(null, item.value)}>
                                        {item.label}
                                    </CustomCheckBox>
                                )
                            }
                        })
                    }
                </LargeScreen>}

                <ButtonWrapper>
                    <LeadFormBack onClick={goBack}>
                        Back
                    </LeadFormBack>
                    <LeadFormNext disabled={isNextDisabled} onClick={goNext}>
                        Next
                    </LeadFormNext>
                </ButtonWrapper>
            </ContentWrap>
        </Fragment>
    );
}

const ButtonWrapper = styled.div`
    width: 115%;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

const LargeScreen = styled.div`
    text-align: center;
    width: 690px;
    height: 400px;
`;

export default Q3UnFiledTaxYearsList;
