import { Fragment, useContext, useState } from 'react';
import { ContentWrap, InnerWrap, HeadWrapper } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import CustomRadio from 'components/CustomRadio';
import { useLocalStorage } from 'hooks';
import { isMobile } from "react-device-detect";

const bankruptcy_discharge = {
    "question": "When do you expect your bankruptcy to discharge?",
    "type": "radio",
    "possible_answers": [
        "1-2 Months",
        "3-4 Months",
        "5-6 Months",
        "Not Sure"
    ],
    "answer": ""
};

function Q7BankruptcyDischarge(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    const [disabled, setDisabled] = useLocalStorage('bank-dis-button', true);
    const [value, setValue] = useLocalStorage('bank-dis-radio', 0);
    const [anime, setAnime] = useState('');
    const List = [
        {
            value: 1, label: '1-2 Months',

        },
        {
            value: 2, label: '3-4 Months',

        },
        {
            value: 3, label: '5-6 Months',

        },
        {
            value: 4, label: 'Not Sure',

        },
    ];

    const onChange = e => {
        bankruptcy_discharge.answer = List[e.target.value - 1].label;
        setValue(e.target.value);
        setDisabled(false);
    };

    const goNext = () => {
        if (bankruptcy_discharge.answer === '') {
            bankruptcy_discharge.answer = List[value - 1].label;
        }
        localStorage.setItem('bankruptcy_discharge', JSON.stringify(bankruptcy_discharge));

        if (value === 1) {
            setAnime('animate__animated animate__slideOutDown');
            setTimeout(() => {
                const payload = {
                    progress: 3, nextPath: paths.REVIEW_ALL
                };
                actions.increment(payload);
                history.push(paths.REVIEW_ALL);
            }, 1000);
        }
        else {
            const payload = {
                progress: 15, nextPath: paths.BANKRUPTCY_CONTACT
            };
            actions.increment(payload);
            history.push(paths.BANKRUPTCY_CONTACT);
        }
    }

    const goBack = () => {
        const payload = {
            progress: 10, nextPath: paths.Q7BANKRUPTCY
        };
        actions.decrement(payload);
        history.push(paths.Q7BANKRUPTCY);
    }

    return (
        <Fragment>
            <ContentWrap center className={anime}>
                <HeadWrapper>
                    <PrimaryText>
                        When do you expect your <span className="color-blue">bankruptcy</span> to discharge?
                    </PrimaryText>
                </HeadWrapper>
                <InnerWrap marginTop={isMobile ? `-50px` : `0px`}>
                    <CustomRadio value={value} options={List} onChange={onChange} />
                </InnerWrap>
                <ButtonWrapper>
                    <LeadFormBack onClick={goBack}>
                        Back
                    </LeadFormBack>
                    <LeadFormNext disabled={disabled} onClick={goNext}>
                        Next
                    </LeadFormNext>
                </ButtonWrapper>
            </ContentWrap>
        </Fragment>
    );
}

const ButtonWrapper = styled.div`
    width: 115%;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

export default Q7BankruptcyDischarge;
