import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentWrap, InnerWrap } from 'components/Layout';
import { LeadFormButton } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';
import paths from 'routes/paths';
import styled from 'styled-components';

function YourOptions(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);

    const goNext = () => {
        const payload = {
            progress: 10, nextPath: paths.CONTACT
        };
        actions.increment(payload);
        history.push(paths.CONTACT);
    }
    return (
        <Fragment>
            <ContentWrap>
                <HeadWrapper>
                    <PrimaryText>
                        Let&apos;s find out what your options are.
                    </PrimaryText>
                    <br />
                    <br />
                    <StyledSpan>
                        Now that we know a little about what your situation is, let&apos;s determine what resolution options you&apos;re qualified for.
                    </StyledSpan>
                </HeadWrapper>
                <InnerWrap>

                </InnerWrap>
                <LeadFormButton onClick={goNext}>
                    Get my Results
                </LeadFormButton>
            </ContentWrap>
        </Fragment>
    );
}

const HeadWrapper = styled.div`
		text-align: center;
`

const StyledSpan = styled.span`
    font-size: 20px;
    line-height: 32px;
    padding: 20px;    
`;

export default YourOptions;
