const data = {
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone_number": "",
    "bankruptcy_discharge_date": "",
    "privacy_consent": true,
    "questions": {
        "data": [
            {
                "question": "What prompted you to seek tax relief?",
                "type": "check",
                "possible_answers": [
                    "Received IRS notice",
                    "Garnishment, lien or levy",
                    "Unpaid taxes",
                    "Other"
                ],
                "answer": ""
            },
            {
                "question": "Do you have any unfiled tax years?",
                "type": "radio",
                "possible_answers": [
                    "Yes",
                    "No",
                    "Not sure"
                ],
                "answer": ""
            },
            {
                "question": "Which tax years are unfiled?",
                "type": "check",
                "possible_answers": [
                    "2022",
                    "2021",
                    "2020",
                    "2019",
                    "2018",
                    "2017 or older",
                    "Not sure"
                ],
                "answer": ""
            },
            {
                "question": "How much tax debt do you owe?",
                "type": "radio",
                "possible_answers": [
                    "$5,000 & under",
                    "$5,001 - $10,000",
                    "$10,001 - $25,000",
                    "$25,000-50,001",
                    "$50,001-75,000",
                    "$75,001 & above"
                ],
                "answer": ""
            },
            {
                "question": "Do you owe federal or state taxes?",
                "type": "radio",
                "possible_answers": [
                    "Federal and State",
                    "Federal Only",
                    "State Only"
                ],
                "answer": ""
            },
            {
                "question": "What type of tax issue do you have?",
                "type": "radio",
                "possible_answers": [
                    "Personal",
                    "Business",
                    "Personal and Business"
                ],
                "answer": ""
            },
            {
                "question": "Are you currently in bankruptcy?",
                "type": "radio",
                "possible_answers": [
                    "Yes",
                    "No"
                ],
                "answer": ""
            },
            {
                "question": "When do you expect your bankruptcy to discharge?",
                "type": "radio",
                "possible_answers": [
                    "1-2 Months",
                    "3-4 Months",
                    "5-6 Months",
                    "Not Sure"
                ],
                "answer": ""
            }
        ]
    }
}

export default data;