import { ContentWrap } from 'components/Layout';
import { PageHeader } from 'components/Header';
import { Fragment } from 'react';

function ErrorFallback() {
    return (
        <Fragment>
            <PageHeader style={{ backgroundColor: '#ffc107' }}>
                <h2>Something went wrong!</h2>
                <p>
                    We have been already notified! It is not you it is us! We
                    are taking care of the incidents!
                </p>
            </PageHeader>

            <ContentWrap>
                <h3>
                    In the meantime, you can refresh the page. If the problem
                    still persists, then please wait or contact with us.
                </h3>
                <p>We are really sorry for this inconvenience!</p>
            </ContentWrap>
        </Fragment>
    );
}

export default ErrorFallback;
