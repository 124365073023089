import { Fragment, useEffect, useState } from 'react';
import { ContentWrap, InnerWrap } from 'components/Layout';
import styled from 'styled-components';
import { isMobile } from "react-device-detect";

function GotoCalendly(props) {
    const [seconds, setSeconds] = useState(3);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            const calName = localStorage.getItem('someName');
            const calEmail = localStorage.getItem('someEmail');

            // eslint-disable-next-line no-undef
            window.location = `${process.env.REACT_APP_CALENDLY_URL}?name=${calName}&email=${calEmail}`;
            // if (window.location.host.includes('local') || window.location.host.includes('stage')) {
            //     window.location = `${endpoints.CALENDLY_STAGE_URL}?name=${calName}&email=${calEmail}`;
            // } else {
            //     window.location = `${endpoints.CALENDLY_PROD_URL}?name=${calName}&email=${calEmail}`;
            // }
        }
    }, [seconds]);

    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>

                    <br />
                    <StyledSpan className='color-blue'>

                    </StyledSpan>
                </HeadWrapper>
                <CustomInnerWrap marginTop={isMobile ? `60px` : `60px`} minHeight={`200px`} >

                    <RedirectText>
                        You will be redirected to <span className='color-blue'><strong>Calendly</strong></span> in {seconds}
                    </RedirectText>

                </CustomInnerWrap>
                <BottomStyledSpan>

                </BottomStyledSpan>
            </ContentWrap>
        </Fragment>
    );
}

const RedirectText = styled.div`
    font-size: 25px;
    margin-top: 25vh;
`;


const StyledSpan = styled.span`
    font-size: 18px;
    @media (min-width: 1200px){
        font-size: 24px;
    }
`;

const BottomStyledSpan = styled.span`
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;

    @media (min-width: 1200px){
        font-size: 20px;
    }
`;

const HeadWrapper = styled.div`
		text-align: center;
        margin-top: 30px;
`;

const CustomInnerWrap = styled(InnerWrap)`
    min-height: 300px;

    @media (min-width: 992px){
        min-height: 350px;
    }
`;

export default GotoCalendly;
