import { Button as AntButton } from 'antd';
import styled from 'styled-components';

function Button({ children, ...props }) {
    return <ButtonStyled {...props}>{children}</ButtonStyled>;
}

const ButtonStyled = styled(AntButton)`
    background: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
    height: ${({ theme }) => theme.btnHeight};
    border-radius: ${({ theme }) => theme.btnRadius};
    text-shadow: ${({ theme }) => theme.btnTextShadow};
    font-weight: ${({ theme }) => theme.btnFontWeight};
    font-size: ${({ theme }) => theme.btnFontSize};
    line-height: ${({ theme }) => theme.btnLineHeight};
    &[disabled] {
        background: ${({ theme }) => theme.btnDisableBg};
        color: ${({ theme }) => theme.btnDisableColor};
    }

    &.ant-btn-primary:focus,
    &.ant-btn-primary:hover,
    &:active {
        background: ${({ theme }) => `${theme.primaryColor}93`};
        border-color: ${({ theme }) => `${theme.primaryColor}93`};
    }
`;

export default Button;
