import { contextActionTypes } from 'contexts';

const {
    PROGRESS_INCREMENT,
    PROGRESS_DECREMENT,
    PROGRESS_RESET,    
} = contextActionTypes;

const initialState = localStorage.getItem('initialState') ? Number(localStorage.getItem('initialState')) : 0;

const createActions = dispatch => ({
    increment: value => dispatch({
        type: PROGRESS_INCREMENT, payload: value
    }),
    decrement: value => dispatch({
        type: PROGRESS_DECREMENT, payload: value
    }),
    reset: value => dispatch({
        type: PROGRESS_RESET, payload: value
    })
});

const reducerProgressBar = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROGRESS_INCREMENT:
            {
                const progress = state + payload.progress;
                const currentPath = payload.nextPath;
                localStorage.setItem('path', currentPath);
                localStorage.setItem('initialState', progress);
                return progress;
            }
        case PROGRESS_DECREMENT:
            {
                const progress = state - payload.progress;
                const currentPath = payload.nextPath;
                localStorage.setItem('path', currentPath);
                localStorage.setItem('initialState', progress);
                return progress;
            }
        case PROGRESS_RESET:
            {
                const progress = 0;
                localStorage.setItem('initialState', 0);
                return progress;
            }
        default:
            return new Error('Action type not found!');
    }
}

export default reducerProgressBar;
export { initialState, createActions };