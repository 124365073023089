import { Fragment } from 'react';
import { ContentWrap, InnerWrap, HeadWrapper } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import CustomRadio from 'components/CustomRadio';
import { useLocalStorage } from 'hooks';
import { isMobile } from "react-device-detect";

const tax_issue_types = {
    "question": "What type of tax issue do you have?",
    "type": "radio",
    "possible_answers": [
        "Personal",
        "Business",
        "Personal and Business"
    ],
    "answer": ""
};

function Q6TaxIssueTypes(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    const [disabled, setDisabled] = useLocalStorage('tax-type-button', true);
    const [value, setValue] = useLocalStorage('tax-type-radio', 0);
    const List = [
        {
            value: 1, label: 'Personal',

        },
        {
            value: 2, label: 'Business',

        },
        { value: 3, label: 'Personal and Business' }
    ];

    const onChange = e => {
        tax_issue_types.answer = List[e.target.value - 1].label;
        setDisabled(false);
        setValue(e.target.value);
    };

    const goNext = () => {
        if (tax_issue_types.answer === '') {
            tax_issue_types.answer = List[value - 1].label;
        }
        localStorage.setItem('tax_issue_types', JSON.stringify(tax_issue_types));
        const payload = {
            progress: 10, nextPath: paths.Q7BANKRUPTCY
        };
        actions.increment(payload);
        history.push(paths.Q7BANKRUPTCY)
    }

    const goBack = () => {
        const payload = {
            progress: 10, nextPath: paths.Q5FEDERAL_OR_STATE
        };
        actions.decrement(payload);
        history.push(paths.Q5FEDERAL_OR_STATE);
    }

    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        What type of <span className="color-blue">tax issue</span> <br /> do you have?
                    </PrimaryText>
                </HeadWrapper>
                <InnerWrap marginTop={isMobile ? `-60px` : `0px`}>
                    <CustomRadio value={value} options={List} onChange={onChange} />
                </InnerWrap>
                <ButtonWrapper>
                    <LeadFormBack onClick={goBack}>
                        Back
                    </LeadFormBack>
                    <LeadFormNext disabled={disabled} onClick={goNext}>
                        Next
                    </LeadFormNext>
                </ButtonWrapper>
            </ContentWrap>
        </Fragment>
    );
}

const ButtonWrapper = styled.div`
    width: 115%;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

export default Q6TaxIssueTypes;
