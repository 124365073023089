import { Radio } from 'antd';
import styled from 'styled-components';

function CustomRadio({ value = 0, options, onChange }) {
    return (
        <RadioButtonWrapper>
            <Radio.Group value={value} onChange={onChange}>
                {
                    options.map((item => { return (<Radio key={item.value} value={item.value}>{item.label}</Radio>) }))
                }
            </Radio.Group>
        </RadioButtonWrapper>
    );
}

const RadioButtonWrapper = styled.div`
    .ant-radio-group {
        
        text-align: left;
        width: 328px;
        max-height: 72px;
    }
    .ant-radio-wrapper {
        display: block;
        width: 100%;
        border: 1px solid #bbb;
        padding: 21px 15px;
        border-radius: 3px;
        max-width: 328px;
        max-height: 72px;
        margin: 10px auto;
        &-checked {
            background: #2b6397;
			border: 1px solid #2b6397;
			color: #fff;
            .ant-radio-inner {
                border-color: #2b6397;
                    :after{
                    content: "";
                    display: block;
                    width: 8px;
                    height: 5px;
                    border-bottom: 2px solid #2b6397;
                    border-left: 2px solid #2b6397;
                    transform: rotate(-45deg) scale(1);
                    transition: transform ease 0.25s;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    background: transparent;
                    border-radius: 0px;
                }
            }
        }
    }
`

export default CustomRadio;