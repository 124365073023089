import { Fragment, useEffect, useState } from 'react';
import { ContentWrap, InnerWrap } from 'components/Layout';
import { LeadFormButton } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import styled from 'styled-components';
import { leadCall } from 'assets/img';
import { ToastContainer, toast } from 'react-toastify';
import { isMobile } from "react-device-detect";
import { useHistory } from 'react-router-dom';
import paths from 'routes/paths';
function ThankYou(props) {
    const history = useHistory();
    const notify = () => toast.success("Form submitted");
    const [animeClass, setAnimeClass] = useState('');
    const [firstName, setFirstName] = useState(JSON.parse(localStorage.getItem('firstName')));    
    useEffect(() => {
        notify();
        setTimeout(() => {
            const fn = JSON.parse(localStorage.getItem('firstName'));
            const email = JSON.parse(localStorage.getItem('email'));
            setFirstName(fn);
            localStorage.clear();
            localStorage.setItem('clear', true);
            localStorage.setItem('someName', fn);
            localStorage.setItem('someEmail', email);
            setAnimeClass('Phone is-animating');
        }, 1000);
    }, []);

    const goNext = () => {
        history.push(paths.GOING_TO_CALENDLY);
    }
    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        Thank you, <strong> <span className='color-blue'>{firstName}.</span></strong>
                    </PrimaryText>
                    <br />
                    <StyledSpan className='color-blue'>
                        Determine your next steps with a free,<br /> no-obligation tax consultation.
                    </StyledSpan>
                </HeadWrapper>
                <CustomInnerWrap marginTop={isMobile ? `60px` : `60px`} minHeight={`200px`} >
                    <img src={leadCall} alt='Calling' />
                    <i className={animeClass}></i>
                </CustomInnerWrap>
                <BottomStyledSpan>
                    Schedule an appointment for a free, no-obligation tax consultation to determine your eligibility for tax relief.
                </BottomStyledSpan>
                <LeadFormButton onClick={goNext}>
                    Schedule Appointment
                </LeadFormButton>
            </ContentWrap>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={'colored'}
            />
        </Fragment>
    );
}

const StyledSpan = styled.span`
    font-size: 18px;
    @media (min-width: 1200px){
        font-size: 24px;
    }
`;

const BottomStyledSpan = styled.span`
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;

    @media (min-width: 1200px){
        font-size: 20px;
    }
`;

const HeadWrapper = styled.div`
		text-align: center;
        margin-top: 30px;
`;

const CustomInnerWrap = styled(InnerWrap)`
    min-height: 300px;

    @media (min-width: 992px){
        min-height: 350px;
    }
`;

export default ThankYou;
