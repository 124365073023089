import { ContentWrap } from 'components/Layout';
import { Result } from 'antd';

function PageNotFound() {
    return (
        <ContentWrap>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />
        </ContentWrap>
    );
}

export default PageNotFound;
