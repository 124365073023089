import { logoImg, phoneIcon } from 'assets/img';
import styled from 'styled-components';
import { Progress } from 'antd';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';

function Header({ style, onLogoClick }) {
    const { progressValue } = useContext(ProgressBarContext);
    const handleLogoClick = () => {
        if (onLogoClick) {
            onLogoClick();
        } else {
            return;
        }
    };

    return (
        <Wrapper style={style}>
            <LogoDiv>
                <div>
                    <img 
                        title='TaxRise'
                        className="main-logo"
                        src={logoImg}
                        alt="TaxRise"
                        onClick={handleLogoClick}
                    />
                </div>
                <a title='Call TaxRise' href="tel:+18449344766">
                    <img
                        className="phone-icon"
                        src={phoneIcon}
                        alt="Call TaxRise"
                    />
                    <PhoneSpan className='color-blue'>(844) 934-4766</PhoneSpan>
                </a>
            </LogoDiv>
            <Progress percent={progressValue} showInfo={false} strokeColor="#008AC8" />
        </Wrapper>
    );
}

const Wrapper = styled.header`
    height: 58px;
    position: fixed;
    top: 0;
    left: ${({ collapsed }) => (collapsed ? '0' : '240px')};
    right: 0;
    background-color: #fff;
    z-index: 999;

    .main-logo {
        height: 20px;
        cursor: pointer;
    }

    @media (min-width: 992px) {
        box-shadow: none;
    }
`;

const LogoDiv = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0px 15px;
`;

const PhoneSpan = styled.span`
    font-size: 16px;
    font-weight: 500;
`;

export default Header;
