
import { Fragment, useEffect } from 'react';
import { ContentWrap } from 'components/Layout';
import { LeadFormNext } from 'components/Button';
import { PrimaryText, OptionalText } from 'components/Typography';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { Form } from 'antd';


function ThankYou({ submitForm, onSubmitFailed, form }) {
    const notify = () => toast.success("Form submitted", { theme: 'colored' });
    useEffect(() => {
        notify();

        setTimeout(() => {
            localStorage.clear();
            localStorage.setItem('clear', true);
        }, 1000);
    }, []);

    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        Thank you!
                    </PrimaryText>
                    <br />
                    <NextNote className='color-blue'>
                        What happens next?
                    </NextNote>
                    <br />
                    <br />
                    <OptionalText>
                        We’ll contact you after your bankruptcy is discharged. Feel free to reach out to us at any time if you have any questions.
                    </OptionalText>
                </HeadWrapper>

                <FormWrapper>
                    <Form>
                        <br />
                        <Form.Item>
                            <LeadFormNext onClick={() => window.location = 'https://www.taxrise.com/'} >
                                Back to TaxRise.com
                            </LeadFormNext>
                        </Form.Item>
                    </Form>
                </FormWrapper>

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </ContentWrap>
        </Fragment>
    );
}

const HeadWrapper = styled.div`
		text-align: center;
        width: 90%;
        margin-top: 25vh;
`;

const FormWrapper = styled.div`
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
`;

const NextNote = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
`;

export default ThankYou;
