import { Button as AntButton } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const todoIconStyle = {
    margin: '11.52px 0px 0px 0px',
};
const iconStyle = {
    margin: '34px 47.5px 35px 41px',
};

function Action(props) {
    const {
        link,
        title,
        footer,
        disabled,
        icon,
        onClick,
        className
    } = props;

    const handleClick = () => {
        if (!onClick || disabled || link) return;

        onClick();
    };

    const button = (
        <Button
            block
            disabled={disabled}
            onClick={handleClick}
            className={className}
        >
            <p className="header-wrapper">{title}</p>
            <hr className="border-wrapper"/>
            {icon && <img src={icon} alt="" style={footer ? todoIconStyle: iconStyle}/>}
            {footer && <p className="footer-wrapper">{footer}</p>}
        </Button>
    );

    if (link && !disabled) return <Link to={link}>{button}</Link>;

    return button;
}

Action.propTypes = {
    link: PropTypes.string,
    title: PropTypes.any.isRequired,
    footer: PropTypes.any,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const Button = styled(AntButton)`   
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-height: 146px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    padding: 0px;
    white-space: pre-wrap;
    img {
      width: auto;
      height: 34.62px;      
    }   
    .border-wrapper {
      background: #F5F5F5;
      border: 1px solid #F4F4F4;
      margin: 0px;
    }
    .header-wrapper {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        align-items: center;
        text-align: center;        
        color: #0F212E;
        margin: 7px 0px 3px 0px;      
        height: 20px;        
        @media (max-width: 320px){
          font-size: 10px;
        }        
    }
    .footer-wrapper {
        font-size: 10px;
        line-height: 15px;
        color: #6A7581;
        padding: 14.47px 11px 15px 11px;  
    }
    &.ant-btn[disabled] {
        opacity: 0.2;
        color: #333;
        background: #fff;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        position: relative;
        &:after {
            position: absolute;
            content: ' ';
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: rgba(255, 255, 255, 0.4);
        }
    }    
`;

export default Action;
