import styled from 'styled-components';

function SecondaryText({ children, ...props }) {
    return <Text {...props}>{children}</Text>;
}

const Text = styled.p`
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin: 0 !important;

    @media (min-width: 768px){
        font-size: 18px;
    }

    @media (min-width: 992px){
        font-size: 24px;
        line-height: 24px;
    }

    @media (min-width: 1200px){
        font-size: 22px;
        line-height: 24px;
    }
`;

export default SecondaryText;
