import PropTypes from 'prop-types';
import styled from 'styled-components';

ChartInfo.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    style: PropTypes.object,
    className: PropTypes.string,
    small: PropTypes.bool,
};

function ChartInfo({ title, data, style, className, small }) {
    return (
        <StyledInfo style={style} className={className} small={small}>
            <small>{title}</small>
            <h3>{data}</h3>
        </StyledInfo>
    );
}

const StyledInfo = styled.div`
    small {
        font-size: ${({ small }) => (small ? '10px' : '12px')};
        color: ${({ theme }) => theme.textColorGray};
        line-height: ${({ small }) => (small ? '15px' : '18px')};
    }
    h3 {
        font-size: ${({ small }) => (small ? '12px' : '20px')};
        font-weight: 500;
        line-height: ${({ small }) => (small ? '18px' : '30px')};
        color: ${({ theme }) => theme.textColor};
        margin-bottom: 0;
    }
`;

export default ChartInfo;
