import { Checkbox } from 'antd';
import styled from 'styled-components';
import 'antd/dist/antd.css';
function CustomCheckBox({ children, isActive, toggle, value = 0, checked, className = '' }) {
	return (
		<Wrapper className={className}>
			<Checkbox className={isActive ? 'active' : 'inactive'} checked={checked} onChange={toggle}>{children}</Checkbox>
		</Wrapper>
	);
}

const Wrapper = styled.div`
		width: 328px;
		margin: 0 auto;
		margin-bottom: 10px;
		
		.ant-checkbox-wrapper {
			border: 1px solid #bbb;
			width: 100%;
			padding: 20px 15px;
			border-radius: 4px;
			font-size: 18px;
			line-height: 30px;
		}
		.ant-checkbox-wrapper.active {
			background-color: #2B6397;
			border: 1px solid #2B6397;
		}
		.ant-checkbox-wrapper.active > span {
			color: #fff;
		}
		.ant-checkbox-wrapper.active .ant-checkbox-inner::after {
			border-color: #2b6397;
		}
		.ant-checkbox-wrapper.active .ant-checkbox-inner {
			background-color: #fff;
			border-color: #fff;
		}  
`

export default CustomCheckBox;