import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentWrap } from 'components/Layout';
import { LeadFormNext } from 'components/Button';
import { PrimaryText, SecondaryText } from 'components/Typography';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';
import paths from 'routes/paths';
import styled from 'styled-components';

function Intro(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    useEffect(() => {
        const payload = {
            progress: 0, nextPath: ''
        };
        actions.reset(payload);
    }, [actions]);

    const goNext = () => {
        const payload = {
            progress: 10, nextPath: paths.Q2WHAT_PROMPTED
        };
        actions.increment(payload);
        history.push(paths.Q2WHAT_PROMPTED);
    }
    return (
        <Fragment>
            <ContentWrap>
                <HeadWrapper>
                    <PrimaryText>
                        Interested in <span className="color-blue">tax relief?</span>
                    </PrimaryText>
                    <br />
                    <SecondaryText>
                        Answer a few questions to learn your tax relief options.
                    </SecondaryText>
                </HeadWrapper>
                <IntroWrap>

                </IntroWrap>
                <LeadFormNext onClick={goNext}>
                    Get Started
                </LeadFormNext>
            </ContentWrap>
        </Fragment>
    );
}

const HeadWrapper = styled.div`
		text-align: center;
        margin-top: 23vh;

        @media (min-width: 992px){
            margin-top: 30vh;
        }
    
        @media (min-width: 1200px){        
            margin-top: 30vh;
        }
`

const IntroWrap = styled.div`
    text-align: center;
    width: 325px;
    min-height: 70px;

    @media (min-width: 992px){
    }

    @media (min-width: 1200px){        
    }
`;

export default Intro;
