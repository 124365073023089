import styled from 'styled-components';

const Heading4 = ({ children }) => {
    return <Text>{children}</Text>;
};

const Text = styled.h4`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0f212e;
    margin-bottom: 12px;
`;

export default Heading4;
