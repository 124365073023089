import Layout from 'containers/Layout';
import { useHistory } from 'react-router-dom';
import paths from './paths';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';

function PublicLayout({ children }) {
    const { actions } = useContext(ProgressBarContext);
    const { push } = useHistory();

    const redirectToLogin = () => {
        actions.reset(0);
        localStorage.setItem('path', '/');
        push(paths.INTRO);
    };

    return (
        <Layout onLogoClick={redirectToLogin} publicPage>
            {children}
        </Layout>
    );
}

export default PublicLayout;
