import Button from './Button';
import styled from 'styled-components';
import { documentUpload } from 'assets/img';

function UploadButton({ children, ...props }) {
    return (
        <ButtonStyled {...props} size="sm">
            <img src={documentUpload} />
            {children}
        </ButtonStyled>
    );
}

const ButtonStyled = styled(Button)`
    border: none;
    background: #fff;
    padding: 0px;
    height: 24px;
    margin-right: 12px;
    box-shadow: none;

    img {
        height: 15.75px;
        width: auto;
    }

    &:hover,
    &.active {
        background-color: #ffffff;
        border-color: #ffffff;
        color: #fff;
    }
    &:focus {
        box-shadow: none !important;
    }
`;

export default UploadButton;
