import styled from 'styled-components';

const TextBold = ({ children, ...props }) => {
    return <Text {...props}>{children}</Text>;
};

const Text = styled.h3`
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #0f212e;
`;

export default TextBold;
