import { Fragment, useContext, useEffect, useCallback } from 'react';
import { ContentWrap, InnerWrap } from 'components/Layout';
import { PrimaryText } from 'components/Typography';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';

function ReviewAll(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);

    useEffect(() => {

        setTimeout(() => {
            goNext();
        }, 2500);
    }, [goNext]);

    const goNext = useCallback(
        () => {
            const payload = {
                progress: 10, nextPath: paths.CONTACT
            };
            actions.increment(payload);
            history.push(paths.CONTACT)
        },
        [actions, history],
    );

    return (
        <Fragment>
            <ContentWrap className='animate__animated animate__slideInDown' center>
                <HeadWrapper>
                    <PrimaryText>
                        Reviewing your answers
                    </PrimaryText>
                    <DotWrapper>
                        <div className='dot'></div>
                        <div className='dot'></div>
                        <div className='dot'></div>
                    </DotWrapper>
                </HeadWrapper>
                <InnerWrap>

                </InnerWrap>
            </ContentWrap>
        </Fragment>
    );
}

const HeadWrapper = styled.div`
		text-align: center;
        margin-top: 35vh;

        @media (min-width: 992px){
            margin-top: 30vh;
        }
`;

const DotWrapper = styled.div`
    margin-top: 60px;
    display: flex;
    justify-content: center;
`;

export default ReviewAll;
