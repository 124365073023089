import styled from 'styled-components';
import { Button } from 'antd';

const handleColorType = (color) => {
    switch (color) {
        case 'grey':
            return '#D4D5D6';
        default:
            return '#008ac8';
    }
};

const hoverColorType = (color) => {
    switch (color) {
        case 'grey':
            return '#6a7581';
        default:
            return '#99CC00';
    }
};

function ButtonBlock(props) {
    return (
        <BtnBlock type="primary" {...props}>
            {props.children}
        </BtnBlock>
    );
}

const BtnBlock = styled(Button)`
    && {
        max-width: none;
        width: 100%;
        text-align: center;
        line-height: 40px;
        height: 40px;
        border-radius: 0;
        border: 0px;
        padding: 0;
        background: ${({ color }) => handleColorType(color)};
        &[disabled] {
            background-color: #c6c6c6;
        }
        :hover {
            background-color: ${({ color }) => hoverColorType(color)};
        }

        &[disabled]:hover {
            background-color: #c6c6c6;
        }
        & > span {
            position: relative;
        }
    }
`;

export default ButtonBlock;
