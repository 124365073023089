import { Button as AntButton } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const iconStyleLeft = {
    maxWidth: '20px',
    marginRight: '15px',
};
const iconStyle = {
    maxWidth: '20px',
};
function Direction(props) {
    const {
        link,
        title,
        disabled,
        iconLeft,
        iconRight,
        onClick,
        className,
        style,
    } = props;

    const handleClick = () => {
        if (!onClick || disabled || link) return;

        onClick();
    };

    const button = (
        <Button
            block
            disabled={disabled}
            onClick={handleClick}
            className={className}
            style={style}
        >
            {iconLeft && <img src={iconLeft} alt="" style={iconStyleLeft} />}
            {title}
            {iconRight && <img src={iconRight} alt="" style={iconStyle} />}
        </Button>
    );

    if (link && !disabled) return <Link to={link}>{button}</Link>;

    return button;
}

Direction.propTypes = {
    link: PropTypes.string,
    title: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
};

const Button = styled(AntButton)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border: 0px solid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    img {
        width: 21px;
        height: auto;
    }
    span {
        width: 100%;
        text-align: left;
        display: block;
    }
    &.ant-btn[disabled] {
        color: #333;
        background: #fff;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        position: relative;
        &:after {
            position: absolute;
            content: ' ';
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: rgba(255, 255, 255, 0.4);
        }
    }
    &.light-border {
        border: 1px solid #FAFAFA;
    }
`;

export default Direction;
