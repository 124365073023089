import styled from 'styled-components';

function Body({ children, collapsed, style }) {
    return (
        <Wrapper collapsed={collapsed} style={style}>
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.main`
    margin-top: 58px;
    position: relative;
    flex: 1;
    margin-left: ${({ collapsed }) => (collapsed ? '0' : '240px')};
`;

export default Body;
