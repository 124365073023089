import styled from 'styled-components';

function PrimaryText({ children, ...props }) {
    return <Text {...props}>{children}</Text>;
}

const Text = styled.p`
    font-size: 28px;
    line-height: 40px;
    font-weight: 300;
    letter-spacing: 0.0025em;
    margin: 0 !important;

    @media (min-width: 768px){
        font-size: 28px;
    }

    @media (min-width: 992px){
        font-size: 42px;
        line-height: 56px;
    }

    @media (min-width: 1200px){
        font-size: 42px;
        line-height: 56px;
    }
`;

export default PrimaryText;
