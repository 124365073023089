import styled from 'styled-components';

function HeadWrapper({ children, ...props }) {
    return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled.div`
    text-align: center;
    margin-top: 50px;
    min-height: 145px;
`;

export default HeadWrapper;