import { Fragment, useContext } from 'react';
import { ContentWrap, InnerWrap, HeadWrapper } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import CustomRadio from 'components/CustomRadio';
import { useLocalStorage } from 'hooks';
import { isMobile } from "react-device-detect";

const unfiled_tax = {
    "question": "Do you have any unfiled tax years?",
    "type": "radio",
    "possible_answers": [
        "Yes",
        "No",
        "Not sure"
    ],
    "answer": ""
};

const unfiled_tax_years = {
    "question": "Which tax years are unfiled?",
    "type": "check",
    "possible_answers": [
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017 or older",
        "Not sure"
    ],
    "answer": []
};

function Q2UnFiledTaxYears(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);

    const List = [
        {
            value: 1, label: 'Yes',
        },
        {
            value: 2, label: 'No',
        },
        { value: 3, label: 'Not sure' }
    ];

    const [value, setValue] = useLocalStorage('unfiled-tax-radio', 0);

    const onChange = e => {
        unfiled_tax.answer = List[e.target.value - 1].label;
        setValue(e.target.value);
    };

    const goNext = () => {

        localStorage.setItem('unfiled_tax_years', JSON.stringify(unfiled_tax_years));
        localStorage.setItem('unfiled_tax', JSON.stringify(unfiled_tax));
        if (value === 1) {
            const payload = {
                progress: 10, nextPath: paths.Q3UN_FILED_TAX_YEARS_LIST
            };
            actions.increment(payload);
            history.push(paths.Q3UN_FILED_TAX_YEARS_LIST);
        }
        else {
            if (localStorage.getItem('unfiled-list-visited')) {
                localStorage.removeItem('unfiled-list-visited');
            }
            const payload = {
                progress: 10, nextPath: paths.Q4TAX_DEBT
            };
            actions.increment(payload);
            history.push(paths.Q4TAX_DEBT);
        }
    }

    const goBack = () => {
        const payload = {
            progress: 10, nextPath: paths.Q2WHAT_PROMPTED
        };
        actions.decrement(payload);
        history.push(paths.Q2WHAT_PROMPTED);
    }

    return (
        <Fragment>

            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        Do you have any <span className="color-blue"> <br /> unfiled</span> tax years?
                    </PrimaryText>
                </HeadWrapper>
                <InnerWrap marginTop={isMobile ? `-60px` : `0px`}>
                    <CustomRadio options={List} value={value} onChange={onChange} />
                </InnerWrap>
                <ButtonWrapper>
                    <LeadFormBack onClick={goBack}>
                        Back
                    </LeadFormBack>
                    <LeadFormNext disabled={value === 0 ? true : false} onClick={goNext}>
                        Next
                    </LeadFormNext>
                </ButtonWrapper>
            </ContentWrap>
        </Fragment>
    );
}

const ButtonWrapper = styled.div`
    width: 115%;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

export default Q2UnFiledTaxYears;
