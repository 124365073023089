import { Button } from 'antd';
import styled from 'styled-components';

function LinkButton({ children, ...props }) {
    return (
        <StyledLinkButton {...props} type="link">
            {children}
        </StyledLinkButton>
    );
}

const StyledLinkButton = styled(Button)`
    color: ${({ theme }) => theme.primaryColor};
`;

export default LinkButton;
