import styled from 'styled-components';

const Separator = () => {
    return <Line></Line>;
};

const Line = styled.div`
    height: 1px;
    background-color: #dcdcdc;
    margin: 8px 0;
`;

export default Separator;
