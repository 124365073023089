import { Spin as AntSpin } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

Spin.propTypes = {
    spinning: PropTypes.bool,
};

function Spin({ spinning, ...props }) {
    return <SpinStyled spinning={spinning} {...props} />;
}

const SpinStyled = styled(AntSpin)`
    .ant-spin-dot-item {
        background-color: ${({ theme }) => theme.primaryColor};
    }
`;

export default Spin;
