import styled from 'styled-components';

function InnerWrap({ children, ...props }) {
    return <Wrapper className='animate__animated animate__fadeIn' {...props}>{children}</Wrapper>;
}

const Wrapper = styled.div`
    text-align: center;
    width: 325px;
    margin-top: ${({ marginTop }) => `${marginTop?marginTop:`-30px`}`};
    min-height: 400px;
    
    @media (min-width: 992px){
        width: 325px;
        min-height: 400px;
    }

    @media (min-width: 1200px){
        width: 325px;
        min-height: 400px;
    }
`;
export default InnerWrap;
