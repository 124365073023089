import { useReducer } from 'react';
import ProgressBarContext from './ProgressBarContext';
import reducerProgressBar, { initialState, createActions } from './reducerProgressBar';

const ProgressBarProvider = props => {    
    const [progressValue, dispatch] = useReducer(reducerProgressBar, initialState);
    const actions = createActions(dispatch);
    return (
        <ProgressBarContext.Provider value={{ progressValue, actions }} >
            {props.children}
        </ProgressBarContext.Provider>
    );
}

export default ProgressBarProvider;