import Button from './Button';
import styled from 'styled-components';

function TagButton({ children, ...props }) {
    return (
        <ButtonStyled {...props} size="sm">
            {children}
        </ButtonStyled>
    );
}

const ButtonStyled = styled(Button)`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    padding: 11px;
    min-width: 82px;
    height: auto;
    color: #b3bac2;
    border-color: #b3bac2;

    &:hover,
    &.active {
        background-color: #0bce9f;
        border-color: #0bce9f;
        color: #fff;
    }
`;

export default TagButton;
