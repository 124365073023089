/* eslint-disable no-undef */
import { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes/Routes';
import ScrollToTop from 'utilities/ScrollToTop';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/GlobalStyles';
import themeConfig from 'styles/styleConfig';
import { LoaderProvider, ProgressBarProvider } from 'contexts';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from 'utilities';
import { IntlProvider } from 'react-intl';
import { purgoTexts, taxriseTexts } from 'locale';
import moment from 'moment';
// Mocking language codes with brand name to solve react-intl error
const brandTexts = {
    de: purgoTexts,
    en: taxriseTexts,
};

const appId = process.env['REACT_APP_PROJECT_ID'];
const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
console.log({ currentTime });

function App() {
    const brand = useMemo(() => {
        switch (appId) {
            case 'purgo':
                return 'de';
            default:
                return 'en';
        }
    }, []);

    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <ThemeProvider theme={themeConfig}>
                <LoaderProvider>
                    <ProgressBarProvider>
                        <Router>
                            <GlobalStyles />
                            <ScrollToTop />
                            <IntlProvider
                                locale={brand}
                                messages={brandTexts[brand]}
                            >
                                <Routes />
                            </IntlProvider>
                        </Router>
                    </ProgressBarProvider>
                </LoaderProvider>
            </ThemeProvider>
        </Sentry.ErrorBoundary>
    );
}
export default App;
