import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import Body from './Body';
import Header from './Header';

function Layout({ children, publicPage, onLogoClick }) {
    if (publicPage) {
        return (
            <LayoutStyled>
                <Header style={{ left: '0' }} onLogoClick={onLogoClick} />
                <Body collapsed>{children}</Body>
            </LayoutStyled>
        );
    }

    return (
        <LayoutStyled>
            <Header onLogoClick={onLogoClick} />
            <LayoutStyled>
                <Body >{children}</Body>
            </LayoutStyled>
        </LayoutStyled>
    );
}

const LayoutStyled = styled(AntLayout)`
    width: 100%;
    background-color: ${(props) => props.theme.layoutBg};
`;

export default Layout;
