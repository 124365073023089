import { useEffect, Suspense, useContext } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import path from './paths';
import { SpinLoader } from 'components/Loader';
import PublicRoute from './PublicRoute';
import {
    PageNotFound,
} from 'utilities';
import { ProgressBarContext } from 'contexts';

import Intro from 'pages/Intro';
import Q1WhatPrompted from 'pages/Q1WhatPrompted';
import Contact from 'pages/Contact';
import BankruptcyContact from 'pages/BankruptcyContact';
import Q2UnFiledTaxYears from 'pages/Q2UnFiledTaxYears';
import Q3UnFiledTaxYearsList from 'pages/Q3UnFiledTaxYearsList';
import Q4TaxDebt from 'pages/Q4TaxDebt';
import Q5FederalOrState from 'pages/Q5FederalOrState';
import Q6TaxIssueTypes from 'pages/Q6TaxIssueTypes';
import Q7Bankruptcy from 'pages/Q7Bankruptcy';
import Q7BankruptcyDischarge from 'pages/Q7BankruptcyDischarge';
import ReviewAll from 'pages/ReviewAll';
import ThankYou from 'pages/ThankYou';
import ThankYouInSide from 'pages/ThankYouInSide';
import YourOptions from 'pages/YourOptions';
import FreeConsultation from 'pages/FreeConsultation';
import GotoCalendly from 'pages/GotoCalendly';
import BankruptcyContactThanks from 'pages/BankruptcyContactThanks';

const Routes = () => {
    const { actions } = useContext(ProgressBarContext);
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    useEffect(() => {

        let paramsToStore = { ...params };

        const localStorageParams = localStorage.getItem('params');

        if (localStorageParams) {

            const localStorageParamsObj = JSON.parse(localStorageParams);

            paramsToStore = { ...localStorageParamsObj, ...paramsToStore };
        }

        localStorage.setItem('params', JSON.stringify(paramsToStore));

        if (window.location.pathname.includes('free-tax-consultation')) {
            localStorage.setItem('event_start_time', params.event_start_time);
            localStorage.setItem('invitee_full_name', params.invitee_full_name);
            history.push(path.FREE_TAX_CONSULTATION);
        } else if (localStorage.getItem('clear')) {
            history.push('/');
            localStorage.removeItem('clear');
        }
        else if (localStorage.getItem('path')) {
            const currentPath = localStorage.getItem('path');
            history.push(currentPath);
        }
        else if (!localStorage.getItem('path') && !localStorage.getItem('clear')) {
            history.push('/');
        }
    }, [actions, history, params])


    return (
        <Suspense fallback={<SpinLoader style={{ margin: '35px auto' }} />}>
            <Switch>
                <PublicRoute exact path={path.INTRO}>
                    <Intro />
                </PublicRoute>
                <PublicRoute exact path={path.Q2WHAT_PROMPTED}>
                    <Q1WhatPrompted />
                </PublicRoute>
                <PublicRoute exact path={path.Q2UN_FILED_TAX_YEARS}>
                    <Q2UnFiledTaxYears />
                </PublicRoute>
                <PublicRoute exact path={path.Q3UN_FILED_TAX_YEARS_LIST}>
                    <Q3UnFiledTaxYearsList />
                </PublicRoute>
                <PublicRoute exact path={path.Q4TAX_DEBT}>
                    <Q4TaxDebt />
                </PublicRoute>
                <PublicRoute exact path={path.Q5FEDERAL_OR_STATE}>
                    <Q5FederalOrState />
                </PublicRoute>
                <PublicRoute exact path={path.Q6TAX_ISSUE_TYPES}>
                    <Q6TaxIssueTypes />
                </PublicRoute>
                <PublicRoute exact path={path.Q7BANKRUPTCY}>
                    <Q7Bankruptcy />
                </PublicRoute>
                <PublicRoute exact path={path.Q7BANKRUPTCY_DISCHARGE}>
                    <Q7BankruptcyDischarge />
                </PublicRoute>
                <PublicRoute exact path={path.REVIEW_ALL}>
                    <ReviewAll />
                </PublicRoute>
                <PublicRoute exact path={path.YOUR_OPTIONS}>
                    <YourOptions />
                </PublicRoute>
                <PublicRoute exact path={path.CONTACT}>
                    <Contact />
                </PublicRoute>
                <PublicRoute exact path={path.BANKRUPTCY_CONTACT}>
                    <BankruptcyContact />
                </PublicRoute>
                <PublicRoute exact path={path.BANKRUPTCY_CONTACT_THANKS}>
                    <BankruptcyContactThanks />
                </PublicRoute>
                <PublicRoute exact path={`${path.THANK_YOU}/:what_promted`}>
                    <ThankYou />
                </PublicRoute>
                <PublicRoute exact path={`${path.THANK_YOU_INSIDE}/:what_promted`}>
                    <ThankYouInSide />
                </PublicRoute>

                <PublicRoute exact path={path.THANK_YOU_RECEIVED_IRS_NOTICE}>
                    <ThankYou />
                </PublicRoute>
                <PublicRoute exact path={path.THANK_YOU_GARNISHMENT_LIEN_LEVY}>
                    <ThankYou />
                </PublicRoute>
                <PublicRoute exact path={path.THANK_YOU_UNPAID_TAXES}>
                    <ThankYou />
                </PublicRoute>
                <PublicRoute exact path={path.THANK_YOU_OTHER}>
                    <ThankYou />
                </PublicRoute>

                <PublicRoute exact path={`${path.FREE_TAX_CONSULTATION}/:event_start_time`}>
                    <FreeConsultation />
                </PublicRoute>
                <PublicRoute exact path={path.FREE_TAX_CONSULTATION}>
                    <FreeConsultation />
                </PublicRoute>
                <PublicRoute exact path={path.GOING_TO_CALENDLY}>
                    <GotoCalendly />
                </PublicRoute>
                <PublicRoute path="*">
                    <PageNotFound />
                </PublicRoute>
            </Switch>
        </Suspense >
    );
};

export default Routes;
