import { Fragment, useEffect, useState } from 'react';
import { ContentWrap } from 'components/Layout';
import { LeadFormButton } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import paths from 'routes/paths';
import { phoneIcon } from 'assets/img';
import moment from 'moment-timezone';
import { useLocalStorage } from 'hooks';


function FreeConsultation(props) {
    const history = useHistory();

    const [firstName, setFirstName] = useState(localStorage.getItem('invitee_full_name'));
    const [eventTime, setEventTime] = useLocalStorage('event_start_time', '');
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const [inviteeId, setInviteeId] = useState('');

    useEffect(() => {
        setTimeout(() => {
            console.log({ params });
            let fn = localStorage.getItem('invitee_full_name');
            fn = !fn || fn === 'undefined' ? 'our dear client' : fn
            setFirstName(fn);

            if (params.event_start_time && params.event_start_time.split(' ')[0]) {
                setEventTime(params.event_start_time.split(' ')[0]);
            }

            if (params.invitee_uuid && params.invitee_uuid != '') {
                setInviteeId(params.invitee_uuid);
            }
        }, 10);
    }, [params, setEventTime]);

    const doReschedule = () => {
        window.location.href = `https://calendly.com/reschedulings/${inviteeId}`;
    }

    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        Thank you, <strong> <span className='color-blue'>{firstName}.</span></strong>
                    </PrimaryText>
                    <br />
                    <StyledSpan>
                        Thank you for scheduling a call. <br />Your tax consultation has been scheduled for:
                    </StyledSpan>
                </HeadWrapper>
                <FreeConsultDiv>
                    <TimeDiv className='animate__animated animate__fadeIn'>
                        <img src={phoneIcon} alt='Calling' /> {moment(eventTime).format("dddd, MMMM Do")}
                    </TimeDiv>
                </FreeConsultDiv>
                <BottomStyledSpan>
                    We look forward to speaking with you!
                </BottomStyledSpan>
                <ScheduleWrapper>
                    <span className='color-blue'>Need to reschedule?</span>
                    <LeadFormButton onClick={doReschedule}>
                        Reschedule Appointment
                    </LeadFormButton>
                </ScheduleWrapper>
            </ContentWrap>

        </Fragment>
    );
}

const FreeConsultDiv = styled.div`
    font-weight: 500;
`;

const TimeDiv = styled.div`
    font-size: 18px;
    
    margin-top: 5vh;
    margin-bottom: 5vh;

    @media (min-width: 1200px){
        font-size: 24px;
    }

    img {
        margin-right: 10px;
    }
`;

const StyledSpan = styled.span`
    font-size: 18px;
    @media (min-width: 1200px){
        font-size: 24px;
    }
`;

const BottomStyledSpan = styled.span`
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;

    @media (min-width: 1200px){
        font-size: 20px;
    }
`;

const HeadWrapper = styled.div`
		text-align: center;
        margin-top: 15vh;
`;


const ScheduleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 24px;
    height: 135px;
    justify-content: space-around;
`;

export default FreeConsultation;
