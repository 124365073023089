import styled from 'styled-components';
import PropTypes from 'prop-types';
import { plusCircleIcon } from 'assets/img';

function IconTextButton({ icon, title, children, ...props }) {
    return (
        <ButtonWrap {...props}>
            <img src={icon} alt="" />
            <span>{title}</span>
        </ButtonWrap>
    );
}

const ButtonWrap = styled.button`
    padding: 0;
    border: 0;
    background: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;

    img {
        height: 18px;
        width: auto;
        margin-right: 16px;
    }

    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${({ theme }) => theme.primaryColor};
    }
`;

IconTextButton.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.node,
    ]),
};

IconTextButton.defaultProps = {
    icon: plusCircleIcon,
};

export default IconTextButton;
