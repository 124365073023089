import { Button as AntButton } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function ActionToDo(props) {
    const {
        link,
        title,
        description,
        disabled,
        icon,
        onClick,
        className,
    } = props;

    const handleClick = () => {
        if (!onClick || disabled || link) return;

        onClick();
    };

    const button = (
        <Button
            block
            disabled={disabled}
            onClick={handleClick}
            className={className}
        >
            <div className="media-icon">
                {icon && <img src={icon} alt="" />}
            </div>
            <div className="media-content">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </Button>
    );

    if (link && !disabled) return <Link to={link}>{button}</Link>;

    return button;
}

ActionToDo.propTypes = {
    link: PropTypes.string,
    title: PropTypes.any.isRequired,
    footer: PropTypes.any,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const Button = styled(AntButton)`
    display: flex;
    justify-content: flex-start;
    background: #fff;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
    border-radius: 4px;
    margin: 10px 0;
    align-items: center;
    padding: 24px;
    height: inherit;
    border: 0px solid transparent;
    width: calc(100% - 20px);
    margin-left: 10px;

    @media (min-width: 768px) {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: baseline;
    }

    @media (max-width: 767px) {
        padding: 15px 12px;
    }

    .media-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: ${({ theme }) => `${theme.primaryColor}20`};
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px) {
            margin-bottom: 16px;
        }

        img {
            max-height: 27px;
            max-width: 27px;
        }
    }
    .media-content {
        width: calc(100% - 65px);
        margin-left: 15px;
        text-align: left;
        @media (min-width: 768px) {
            width: 100%;
            margin-left: 0px;
        }
        h4,
        p {
            margin: 0;
            padding: 0;
            white-space: normal;
        }
        h4 {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #0f212e;
            @media (min-width: 768px) {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
        p {
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #6a7581;
            @media (min-width: 768px) {
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
            }
        }
    }
`;

export default ActionToDo;
