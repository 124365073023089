import styled from 'styled-components';

function LeadFormBack({ children, ...props }) {
    return <ButtonStyled {...props}><span>{children}</span> </ButtonStyled>;
}

const ButtonStyled = styled.button`
    position: relative;
    height: 72px;
    width: 328px;
    background: #fff;
    color: #2B6397;
    font-size: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    cursor:pointer;
    border: 1px solid #2B6397;

    &:disabled {
      background: transparent;
      color: #DDDBDA;
      cursor: not-allowed;
      border: 1px solid #DDDBDA;
      border-radius: 4px;
      box-shadow: none;
      font-size: 16px;
      font-weight: 400;
      span {
        position: relative;
        &:before {
          content: "";
          width: 20px;
          height: 2px;
          background: #DDDBDA;
          position: absolute;
          top: 50%;
          margin-top: -1px;
          transition: all 0.3s ease;
        }
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent #DDDBDA;
          position: absolute;
          top: 50%;
          margin-top: -5px;
          right: -34px;
          transition: all 0.3s ease;
        }
      }
    }
    span {
      position: relative;
      &:before {
        content: "";
        width: 20px;
        height: 2px;
        background: #2B6397;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        right: 50px;
        transition: all 0.3s ease;
      }
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;         
        border-right:5px solid #2B6397;
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 66px;
        transition: all 0.3s ease;
      }
    }
    &:hover {
      span {
        &:before {
          // transform: translateX(-10px);
          transition: all 0.3s ease;
    
          &:disabled {
            right: unset;
            width: unset;
          }
        }
        &:after {
          right: 70px;
          // transform: translateX(-10px);
          transition: all 0.3s ease;
        }
      }
    }
`;

export default LeadFormBack;