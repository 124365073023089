import { useState, Fragment, useContext } from 'react';
import { ContentWrap } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText, OptionalText } from 'components/Typography';
import styled from 'styled-components';
import { Form, Input } from 'antd';
import ThankYou from 'pages/BankruptcyContact/ThankYou';
import data from './data';
import paths from 'routes/paths';
import { useApi, endpoints } from 'api';
import { useLocalStorage } from 'hooks';
import { ProgressBarContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormat from 'react-number-format';


function BankruptcyContact(props) {
    const notify = () => toast("Form submitted");
    const notifyError = () => toast.error("Something went wrong", { theme: 'colored' });
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [form] = Form.useForm();
    const [api] = useApi(false);
    const [firstName, setFirstName] = useLocalStorage('firstName-bank', '');
    const [lastName, setLastName] = useLocalStorage('lastName-bank', '');
    const [email, setEmail] = useLocalStorage('email-bank', '');
    const [phone, setPhone] = useLocalStorage('phone-bank', '');
    const [dischargeDate, setDischargeDate] = useLocalStorage('dischargeDate-bank', '');
    const [disabled, setDisabled] = useLocalStorage('dischargeDate-button', true);

    const submitForm = () => {
        setIsSubmitted(true);
    };

    const onSubmitFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const OnSubmitForm = async () => {
        data.first_name = firstName;
        data.last_name = lastName;
        data.email = email;
        data.phone_number = '+1' + phone.replace('-', '').replace('-', '');;
        data.privacy_consent = true;
        data.bankruptcy_discharge_date = dischargeDate;
        data.questions.data = [];
        data.questions.data.push(JSON.parse(localStorage.getItem('what_prompted')));
        data.questions.data.push(JSON.parse(localStorage.getItem('unfiled_tax')));
        if (localStorage.getItem('unfiled_tax_years')) {
            data.questions.data.push(JSON.parse(localStorage.getItem('unfiled_tax_years')));
        }
        data.questions.data.push(JSON.parse(localStorage.getItem('tax_debt')));
        data.questions.data.push(JSON.parse(localStorage.getItem('federal_or_state')));
        data.questions.data.push(JSON.parse(localStorage.getItem('tax_issue_types')));
        data.questions.data.push(JSON.parse(localStorage.getItem('bankruptcy')));
        if (localStorage.getItem('bankruptcy_discharge')) {
            data.questions.data.push(JSON.parse(localStorage.getItem('bankruptcy_discharge')));
        }
        try {

            console.log(endpoints.LEAD_FORM_ENDPOINT);
            const req = await api.post(endpoints.LEAD_FORM_ENDPOINT, data);
            const res = await req.data;

            if (res.success) {
                notify();
                setIsSubmitted(true);
                const payload = {
                    progress: 100, nextPath: paths.BANKRUPTCY_CONTACT_THANKS
                };
                actions.increment(payload);
            }
            else {
                notifyError();
            }

        } catch (error) {
            console.log({ error });
            console.log('Failed');
            setIsSubmitted(true);
        }
    }

    const onInputChange = (element) => {
        if (element.target.id === 'basic_firstName') {
            setFirstName(element.target.value);
            if (element.target.value.length > 0) {
                setDisabled(false);
            }
            else {
                setDisabled(true);
            }
        }
        else if (element.target.id === 'basic_lastName') {
            setLastName(element.target.value);
            if (element.target.value.length > 0) {
                setDisabled(false);
            }
            else {
                setDisabled(true);
            }
        }
        else if (element.target.id === 'basic_email') {

            // if (!validEmail(element.target.value) || element.target.value == '' || element.target.value.length === 0) {
            //     setDisabled(true);
            // }
            // else {
            //     setDisabled(false);
            // }
            setEmail(element.target.value);
        }
        else if (element.target.id === 'basic_phone') {
            const phoneValue = element.target.value.replaceAll(' ', '');
            if (phoneValue == '' || phoneValue.length < 12) {
                setDisabled(true);
            }
            else {
                setDisabled(false);
            }
            setPhone(element.target.value);
        }
        else if (element.target.id === 'basic_dischargeDate') {
            setDischargeDate(element.target.value);
            if (element.target.value.length > 7) {
                if (firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0) {
                    setDisabled(false);
                }
            }
            else {
                setDisabled(true);
            }
        }
    }

    const goBack = () => {
        const payload = {
            progress: 10, nextPath: paths.Q7BANKRUPTCY_DISCHARGE
        };
        actions.decrement(payload);
        history.push(paths.Q7BANKRUPTCY_DISCHARGE);
    }

    if (isSubmitted) {
        return (
            <ThankYou />
        );
    }

    else {
        return (
            <Fragment>
                <ContentWrap center>
                    <HeadWrapper>
                        <PrimaryText>
                            We need to wait until your bankruptcy is discharged.
                        </PrimaryText>
                        <br />
                        <OptionalText>
                            In the mean time, enter your contact information and we’ll send you our bankruptcy guide checklist to tax relief.
                        </OptionalText>
                    </HeadWrapper>
                    <br />
                    <FormWrapper className='animate__animated animate__fadeIn'>
                        <Form
                            name="basic"
                            initialValues={{}}
                            onFinish={submitForm}
                            onFinishFailed={onSubmitFailed}
                            layout="vertical"
                            form={form}
                        >
                            <NameWrapper>
                                <NameItem>
                                    <Form.Item label="First Name" name="firstName" initialValue={firstName} rules={[
                                    {
                                        required: true, message: 'First Name is required!'
                                    }
                                ]}>
                                        <Input onChange={onInputChange} maxLength={32} />
                                    </Form.Item>
                                </NameItem>
                                <NameItem>

                                    <Form.Item label="Last Name" name="lastName" initialValue={lastName} rules={[
                                        {
                                            required: true, message: 'Last Name is required!'
                                        }
                                    ]}>
                                        <Input onChange={onInputChange} maxLength={32} />
                                    </Form.Item>
                                </NameItem>
                            </NameWrapper>

                            <Form.Item label="Email" name="email" initialValue={email} rules={
                                [{
                                    required: true, message: 'Email is required!'
                                },
                                { type: 'email', message: 'A valid email is required!' }]}>
                                <Input onChange={onInputChange} />
                            </Form.Item>

                            <Form.Item label="Phone" name="phone" initialValue={phone}
                                rules={[
                                    {
                                        required: true, message: 'Phone is required!'
                                    }
                                ]}>
                                <NumberFormat className='ant-input' format="###-###-####" mask=" " onChange={onInputChange} />
                            </Form.Item>

                            <Form.Item label="Discharge Date" name="dischargeDate" initialValue={dischargeDate} rules={[
                                    {
                                        required: true, message: 'Discharge Date is required!'
                                    }
                                ]}>
                                <NumberFormat placeholder="MM/DD/YYYY" className='ant-input' format="##/##/####" mask=" " onChange={onInputChange} />
                            </Form.Item>
                        </Form>
                    </FormWrapper>
                    <ButtonWrapper>
                        <LeadFormBack onClick={goBack}>
                            Back
                        </LeadFormBack>
                        <LeadFormNext disabled={disabled} onClick={OnSubmitForm}>
                            Next
                        </LeadFormNext>
                    </ButtonWrapper>
                </ContentWrap>

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Fragment>
        );
    }
}

const HeadWrapper = styled.div`
		text-align: center;
        width: 90%;
        margin-top: 20px;

        @media (min-width: 992px){
            width: 120%;
            margin-top: 50px;
        }
`;

const FormWrapper = styled.div`
    max-width: 800px;
    width: 80%;
`;

const ButtonWrapper = styled.div`
    width: 115%;
    height: 160px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
        height: 115px;
    }
`;

const NameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

const NameItem = styled.div`
    width: 100%;

    @media (min-width: 992px){
        width: 48%;
    }
`;
export default BankruptcyContact;
