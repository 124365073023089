const INTRO = '/';
const Q2WHAT_PROMPTED = '/what-prompted';
const Q2UN_FILED_TAX_YEARS = '/unfiled-tax';
const Q3UN_FILED_TAX_YEARS_LIST = '/unfiled-tax-years';
const Q4TAX_DEBT = '/tax-debt';
const Q5FEDERAL_OR_STATE = '/federal-or-state';
const Q6TAX_ISSUE_TYPES = '/tax-issue-types';
const Q7BANKRUPTCY = '/bankruptcy';
const Q7BANKRUPTCY_DISCHARGE = '/bankruptcy-discharge';
const REVIEW_ALL = '/review';
const CONTACT = '/contact';
const BANKRUPTCY_CONTACT = '/bankruptcy-contact';
const THANK_YOU = '/thank-you';
const YOUR_OPTIONS = '/your-options';
const THANK_YOU_INSIDE = '/thank-you-during-office-hours';
const FREE_TAX_CONSULTATION = '/free-tax-consultation/';
const GOING_TO_CALENDLY = '/going-to-calendly/';
const BANKRUPTCY_CONTACT_THANKS = '/bankruptcy-contact-thanks';
const THANK_YOU_RECEIVED_IRS_NOTICE = 'thank-you-received-irs';
const THANK_YOU_GARNISHMENT_LIEN_LEVY = 'thank-you-garnishment';
const THANK_YOU_UNPAID_TAXES = 'thank-you-unpaid-taxes';
const THANK_YOU_OTHER = 'thank-you-other';

export default {
    INTRO,
    Q2WHAT_PROMPTED,
    Q2UN_FILED_TAX_YEARS,
    Q3UN_FILED_TAX_YEARS_LIST,
    Q4TAX_DEBT,
    Q5FEDERAL_OR_STATE,
    Q6TAX_ISSUE_TYPES,
    Q7BANKRUPTCY,
    Q7BANKRUPTCY_DISCHARGE,
    REVIEW_ALL,
    CONTACT,
    BANKRUPTCY_CONTACT,
    THANK_YOU,
    YOUR_OPTIONS,
    THANK_YOU_INSIDE,
    FREE_TAX_CONSULTATION,
    GOING_TO_CALENDLY,
    BANKRUPTCY_CONTACT_THANKS,
    THANK_YOU_RECEIVED_IRS_NOTICE,
    THANK_YOU_GARNISHMENT_LIEN_LEVY,
    THANK_YOU_UNPAID_TAXES,
    THANK_YOU_OTHER,
};
