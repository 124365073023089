import { Fragment, useContext } from 'react';
import { ContentWrap } from 'components/Layout';
import { LeadFormNext } from 'components/Button';
import { PrimaryText, OptionalText } from 'components/Typography';
import styled from 'styled-components';
import { Form, Input, Checkbox } from 'antd';
import data from './data';
import { useApi, endpoints } from 'api';
import { useLocalStorage } from 'hooks';
import { useHistory } from 'react-router-dom';
import paths from 'routes/paths';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import { ToastContainer, toast } from 'react-toastify';
import { ProgressBarContext } from 'contexts';


function validEmail(email) {
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    return regex.test(email);
}

function convertToSlug(Text) {
    return Text.toLowerCase()
               .replace(/[^\w ]+/g, '')
               .replace(/ +/g, '-');
  }

function Contact(props) {

    const { actions } = useContext(ProgressBarContext);
    const notify = () => toast.error("Something went wrong", { theme: 'colored' });
    const history = useHistory();
    const [api] = useApi(false);
    const [firstName, setFirstName] = useLocalStorage('firstName', '');
    const [lastName, setLastName] = useLocalStorage('lastName', '');
    const [email, setEmail] = useLocalStorage('email', '');
    const [phone, setPhone] = useLocalStorage('phone', '');
    const [disabled, setDisabled] = useLocalStorage('disabled', true);
    const [checked, setChecked] = useLocalStorage('checked', false);

    const isOfficeHours = () => {
        const format = 'hh:mm a';
        const officeStart = moment('9:00 am', format);
        const officeStartTZ = moment.tz(officeStart, 'America/Los_Angeles').format();
        const officeEnd = moment('5:00 pm', format);
        const officeEndTZ = moment.tz(officeEnd, 'America/Los_Angeles').format();
        const currentTime = moment();
        if (currentTime.isBetween(officeStartTZ, officeEndTZ)) {
            return true;
        }
        else {
            return false;
        }
    }

    const OnSubmitForm = async () => {

        data.first_name = firstName;
        data.last_name = lastName;
        data.email = email;
        data.phone_number = '+1' + phone.replace('-', '').replace('-', '');
        data.privacy_consent = true;
        data.bankruptcy_discharge_date = '';
        data.questions.data = [];
        data.questions.data.push(JSON.parse(localStorage.getItem('what_prompted')));
        data.questions.data.push(JSON.parse(localStorage.getItem('unfiled_tax')));

        if (localStorage.getItem('unfiled_tax_years')) {
            data.questions.data.push(JSON.parse(localStorage.getItem('unfiled_tax_years')));
        }
        data.questions.data.push(JSON.parse(localStorage.getItem('tax_debt')));
        data.questions.data.push(JSON.parse(localStorage.getItem('federal_or_state')));
        data.questions.data.push(JSON.parse(localStorage.getItem('tax_issue_types')));
        data.questions.data.push(JSON.parse(localStorage.getItem('bankruptcy')));

        const what_promted = JSON.parse(localStorage.getItem('what_prompted'))
        const what_promted_url = convertToSlug(what_promted.answer.join(' '))

        if (localStorage.getItem('bankruptcy_discharge')) {
            data.questions.data.push(JSON.parse(localStorage.getItem('bankruptcy_discharge')));
        }

        const paramsObject = JSON.parse(localStorage.getItem('params'));

        for(const paramKey in paramsObject){
            if (paramKey !== null ) {
                data[paramKey] = paramsObject[paramKey];
            }
        }

        try {

            console.log(endpoints.LEAD_FORM_ENDPOINT);
            const req = await api.post(endpoints.LEAD_FORM_ENDPOINT, data);
            const res = await req.data;
            if (res.success) {
                if (isOfficeHours()) {
                    const payload = {
                        progress: 50, nextPath: `${paths.THANK_YOU_INSIDE}/${what_promted_url}`
                    };
                    actions.increment(payload);
                    history.push(`${paths.THANK_YOU_INSIDE}/${what_promted_url}`);
                }
                else {
                    const payload = {
                        progress: 50, nextPath: `${paths.THANK_YOU}/${what_promted_url}`
                    };
                    actions.increment(payload);
                    history.push(`${paths.THANK_YOU}/${what_promted_url}`);
                }
            }
            else {
                notify();
            }

        } catch (error) {
            console.log({ error });
        }
    }

    const onAcceptTerms = (e) => {
        if (phone.length > 0) {
            setDisabled(!e.target.checked);
            setChecked(e.target.checked);
        }
    }

    const onInputChange = (element) => {
        if (element.target.id === 'basic_firstName') {
            setFirstName(element.target.value);
        }
        else if (element.target.id === 'basic_lastName') {
            setLastName(element.target.value);
        }
        else if (element.target.id === 'basic_email') {

            if (!validEmail(element.target.value) || element.target.value == '' || element.target.value.length < 5) {
                setDisabled(true);
            }
            else {
                if (checked) {
                    setDisabled(false);
                }
                else {
                    setDisabled(true);
                }
            }
            setEmail(element.target.value);
        }
        else if (element.target.id === 'basic_phone') {
            const phoneValue = element.target.value.replaceAll(' ', '');
            if (phoneValue == '' || phoneValue.length < 12) {
                setDisabled(true);
            }
            else {
                if (checked) {
                    setDisabled(false);
                }
                else {
                    setDisabled(true);
                }
            }
            setPhone(element.target.value);
        }
    }

    return (
        <Fragment>
            <ContentWrap center>
                <br />
                <br />
                <HeadWrapper>
                    <PrimaryText>
                        You’ve qualified for a <br /> <span className="color-blue"> free tax consultation.</span>
                    </PrimaryText>
                    <br />
                    <OptionalText>
                        Speak with a tax resolution expert on how to qualify for tax relief programs and protect yourself from aggressive IRS collection actions.
                    </OptionalText>
                </HeadWrapper>
                <br />
                <FormWrapper className='animate__animated animate__fadeIn'>
                    <Form
                        name="basic"
                        initialValues={{}}
                        layout="vertical"
                        onFinish={OnSubmitForm}
                    >
                        <Form.Item label="First Name" name="firstName" initialValue={firstName}
                            rules={[
                                {
                                    required: true, message: 'First Name is required!'
                                }
                            ]}>
                            <Input onChange={onInputChange} maxLength={32} />
                        </Form.Item>

                        <Form.Item label="Last Name" name="lastName" initialValue={lastName}
                            rules={[
                                {
                                    required: true, message: 'Last Name is required!'
                                }
                            ]}>
                            <Input onChange={onInputChange} maxLength={32} />
                        </Form.Item>

                        <Form.Item label="Email" name="email" initialValue={email}
                            rules={[
                                {
                                    required: true, message: 'Email is required!'
                                },
                                { type: 'email', message: 'A valid email is required!' }
                            ]}>
                            <Input onChange={onInputChange} />
                        </Form.Item>

                        <Form.Item label="Phone" name="phone" initialValue={phone}
                            rules={[
                                {
                                    required: true, message: 'Phone is required!'
                                }
                            ]}>
                            <NumberFormat className='ant-input' format="###-###-####" mask=" " onChange={onInputChange} />
                        </Form.Item>

                        <ConsentWrapper>
                            I am providing express written consent to be contacted at the phone number provided and any other subscriber or user of this number by TaxRise and/or its affiliates using automatic dialing systems and pre-recorded and artificial voice and text messages anytime from and after your inquiry, to discuss the products and services offered by its affiliates in response to your requests.
                            <br />
                            <br />
                            I waive any registration to any state, federal, or corporate Do Not Call registry for purposes of such calls. I understand consent is not required to purchase goods or services and that message & data rates may apply.
                            <br />
                            <br />
                            <Checkbox onChange={onAcceptTerms}>I agree to these terms and conditions</Checkbox>

                        </ConsentWrapper>
                        <br />
                        <Form.Item>
                            <ButtonWrapper>
                                <LeadFormNext type='submit' disabled={disabled} >
                                    Next
                                </LeadFormNext>
                            </ButtonWrapper>
                        </Form.Item>
                    </Form>

                </FormWrapper>
            </ContentWrap>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Fragment>
    );
}

const HeadWrapper = styled.div`
		text-align: center;
        width: 100%;
`;

const FormWrapper = styled.div`
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
`;

const ConsentWrapper = styled.div`
		text-align: justify;
        width: 100%;
        background: #0000000d;
        padding: 15px;
        font-size: 12px;
        line-height: 16px;
        color: #263238;
`;

const ButtonWrapper = styled.div`
    text-align: center;
`;

export default Contact;
