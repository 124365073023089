import styled from 'styled-components';

function AmountText({
    preText,
    postText,
    style,
    stylePre,
    stylePost,
    className,
}) {
    return (
        <Text style={style} className={className}>
            <span style={stylePre}>{preText}</span>
            <span style={stylePost} className="post">
                {postText}
            </span>
        </Text>
    );
}

const Text = styled.p`
    text-align: center;
    color: #6a7581;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;

    .post {
        font-weight: 500;
        color: #10222e;
    }
`;

export default AmountText;
