import { Fragment } from 'react';
import { ContentWrap, InnerWrap, HeadWrapper } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import CustomRadio from 'components/CustomRadio';
import { useLocalStorage } from 'hooks';
import { isMobile } from "react-device-detect";

const federal_or_state = {
    "question": "Do you owe federal or state taxes?",
    "type": "radio",
    "possible_answers": [
        "Federal and State",
        "Federal Only",
        "State Only"
    ],
    "answer": ""
};

function Q5FederalOrState(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    const [disabled, setDisabled] = useLocalStorage('fed-state-button', true);
    const [value, setValue] = useLocalStorage('fed-state-radio', 0);
    const List = [
        {
            value: 1, label: 'Federal and State',

        },
        {
            value: 2, label: 'Federal Only',

        },
        { value: 3, label: 'State Only' }
    ];


    const onChange = e => {
        federal_or_state.answer = List[e.target.value - 1].label;
        setDisabled(false);
        setValue(e.target.value);
    };

    const goNext = () => {
        if (federal_or_state.answer === '') {
            federal_or_state.answer = List[value - 1].label;
        }
        localStorage.setItem('federal_or_state', JSON.stringify(federal_or_state));
        const payload = {
            progress: 10, nextPath: paths.Q6TAX_ISSUE_TYPES
        };
        actions.increment(payload);
        history.push(paths.Q6TAX_ISSUE_TYPES);
    }

    const goBack = () => {
        const payload = {
            progress: 10, nextPath: paths.Q4TAX_DEBT
        };
        actions.decrement(payload);
        history.push(paths.Q4TAX_DEBT);
    }

    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        Do you owe <span className="color-blue">federal</span> or <span className="color-blue">state</span> taxes?
                    </PrimaryText>
                </HeadWrapper>
                <InnerWrap marginTop={isMobile ? `-60px` : `0px`}>
                    <CustomRadio value={value} options={List} onChange={onChange} />
                </InnerWrap>
                <ButtonWrapper>
                    <LeadFormBack onClick={goBack}>
                        Back
                    </LeadFormBack>
                <LeadFormNext disabled={disabled} onClick={goNext}>
                    Next
                    </LeadFormNext>
                </ButtonWrapper>
            </ContentWrap>
        </Fragment>
    );
}

const ButtonWrapper = styled.div`
    width: 115%;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

export default Q5FederalOrState;
