import { Fragment, useEffect, useState } from 'react';
import { ContentWrap } from 'components/Layout';
import { LeadFormButton } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import styled from 'styled-components';
import { leadCall } from 'assets/img';
import { ToastContainer, toast } from 'react-toastify';
import { isMobile } from "react-device-detect";
import {useHistory} from 'react-router-dom';
import paths from 'routes/paths';

function ThankYouInSide(props) {
    const history = useHistory();
    const notify = () => toast.success("Form submitted", { theme: 'colored' });
    const [animeClass, setAnimeClass] = useState('');
    const[firstName, setFirstName] = useState(JSON.parse(localStorage.getItem('firstName')));
    useEffect(() => {
        notify();
        setTimeout(() => {
            const fn = JSON.parse(localStorage.getItem('firstName'));
            const email = JSON.parse(localStorage.getItem('email'));
            setFirstName(fn);
            localStorage.clear();
            localStorage.setItem('clear', true);
            localStorage.setItem('someName', fn);
            localStorage.setItem('someEmail', email);
            setAnimeClass(isMobile ? 'Phone-in is-animating' : 'Phone-in-desk is-animating');
        }, 1000);
    }, []);

    const goNext = () => {
        history.push(paths.GOING_TO_CALENDLY);
    }
    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        Thank you, <strong> <span className='color-blue'>{firstName}.</span></strong>
                    </PrimaryText>
                    <br />
                    <StyledSpan className='color-blue'>
                        Determine your next steps with a free,<br /> no-obligation tax consultation.
                    </StyledSpan>
                </HeadWrapper>
                <ThankYouWrapper className='animate__animated animate__fadeIn'>
                    <img src={leadCall} alt='Calling' />
                    <i className={animeClass}></i>
                    <br/>
                    <CallerText>
                        We’re about to give you a call.
                    </CallerText>
                </ThankYouWrapper>
                <BottomStyledSpan>
                    Answer your phone and be immediately connected with a TaxRise representative.
                </BottomStyledSpan>
                <ScheduleWrapper>
                    <span className='color-blue'>Is now not a good time?</span>
                    <LeadFormButton onClick={goNext}>
                        Schedule Appointment
                    </LeadFormButton>
                </ScheduleWrapper>
            </ContentWrap>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Fragment>
    );
}

const StyledSpan = styled.span`
    font-size: 18px;
    @media (min-width: 1200px){
        font-size: 24px;
    }
`;

const BottomStyledSpan = styled.span`
    font-size: 16px;
    text-align: center;
    @media (min-width: 1200px){
        font-size: 20px;
    }
`;

const CallerText = styled.span`
    font-size: 24px;
`;

const ThankYouWrapper = styled.div`
    text-align: center;
    width: 500px;
    margin-top: 30px;

    @media (min-width: 768px){
        width: 500px;
    }

    @media (min-width: 992px){
        width: 600px;
    }

    @media (min-width: 1200px){
        width: 800px;
    }
`;

const ScheduleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 24px;
    height: 135px;
    justify-content: space-around;
`;

const HeadWrapper = styled.div`
		text-align: center;
        margin-top: 30px;
`
export default ThankYouInSide;
