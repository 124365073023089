import styled from 'styled-components';

function ContentWrap({ children, ...props }) {
    return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled.div`
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-content: center;
    align-items: center;
`;

export default ContentWrap;