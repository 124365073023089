import styled from 'styled-components';

function SubHeader({ children, ...props }) {
    return <Text {...props}>{children}</Text>;
}

const Text = styled.h3`
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #6a7581;
`;

export default SubHeader;
