/* eslint-disable no-undef */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
console.log({ API_ENDPOINT });

let API = 'https://app.taxrise.com/api';
if (window.location.href.includes('local') || window.location.href.includes('stage')) {
    API = 'https://leadform-backend-stage.taxrise.com';
}

// API = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_ENDPOINT}`

const LEAD_FORM_ENDPOINT = `${API}/data/save/`;
const CALENDLY_PROD_URL = 'https://calendly.com/taxrise-consult/free-consult';
const CALENDLY_STAGE_URL = 'https://calendly.com/tariq-19/free-tax-consultation-stage';

export default {
    LEAD_FORM_ENDPOINT,
    CALENDLY_PROD_URL,
    CALENDLY_STAGE_URL
};
