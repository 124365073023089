import { Fragment } from 'react';
import { ContentWrap, InnerWrap, HeadWrapper } from 'components/Layout';
import { LeadFormNext, LeadFormBack } from 'components/Button';
import { PrimaryText } from 'components/Typography';
import { useContext } from 'react';
import { ProgressBarContext } from 'contexts';
import styled from 'styled-components';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import CustomRadio from 'components/CustomRadio';
import { useLocalStorage } from 'hooks';
import { isMobile } from "react-device-detect";

const List = [
    {
        value: 1, label: '$5,000 & under',

    },
    {
        value: 2, label: '$5,001 - $10,000',

    },
    {
        value: 3, label: '$10,001 - $25,000',

    },
    {
        value: 4, label: '$25,001 - $50,000',

    },
    {
        value: 5, label: '$50,001 - $75,000',

    },
    {
        value: 6, label: '$75,001 & above',

    },
];

const ListOne = [
    {
        value: 1, label: '$5,000 & under',

    },
    {
        value: 2, label: '$5,001 - $10,000',

    },
    {
        value: 3, label: '$10,001 - $25,000',

    },
];

const ListTwo = [
    {
        value: 4, label: '$25,001 - $50,000',

    },
    {
        value: 5, label: '$50,001 - $75,000',

    },
    {
        value: 6, label: '$75,001 & above',

    },
];

const tax_debt = {
    "question": "How much tax debt do you owe?",
    "type": "radio",
    "possible_answers": [
        "$5,000 & under",
        "$5,001 - $10,000",
        "$10,001 - $25,000",
        "$25,000-50,001",
        "$50,001-75,000",
        "$75,001 & above"
    ],
    "answer": ""
};

function Q4TaxDebt(props) {
    const history = useHistory();
    const { actions } = useContext(ProgressBarContext);
    const [disabled, setDisabled] = useLocalStorage('tax-debt-button', true);
    const [value, setValue] = useLocalStorage('tax-debt-radio', 0);

    const onChange = e => {
        tax_debt.answer = List[e.target.value - 1].label;
        setDisabled(false);
        setValue(e.target.value);
    };

    const goNext = () => {
        if (tax_debt.answer === '') {
            tax_debt.answer = List[value - 1].label;
        }
        localStorage.setItem('tax_debt', JSON.stringify(tax_debt));
        const payload = {
            progress: 10, nextPath: paths.Q5FEDERAL_OR_STATE
        };
        actions.increment(payload);
        history.push(paths.Q5FEDERAL_OR_STATE)
    }

    const goBack = () => {

        if (localStorage.getItem('unfiled-list-visited') && localStorage.getItem('unfiled-list-visited') === 'visited') {
            const payload = {
                progress: 10, nextPath: paths.Q3UN_FILED_TAX_YEARS_LIST
            };
            actions.decrement(payload);
            history.push(paths.Q3UN_FILED_TAX_YEARS_LIST);
        }
        else {
            const payload = {
                progress: 10, nextPath: paths.Q2UN_FILED_TAX_YEARS
            };
            actions.decrement(payload);
            history.push(paths.Q2UN_FILED_TAX_YEARS);
        }

    }

    console.log({isMobile});
    return (
        <Fragment>
            <ContentWrap center>
                <HeadWrapper>
                    <PrimaryText>
                        How much <span className="color-blue">tax debt</span> <br />do you owe?
                    </PrimaryText>
                </HeadWrapper>
                {
                    isMobile ? <InnerWrap style={{ height: '525px' }} marginTop={`-60px`}>
                        <CustomRadio options={List} value={value} onChange={onChange} />
                    </InnerWrap> : <InnerWrap marginTop={`0px`}>
                        <DeskRadioWrapper>
                            <div style={{ marginRight: '20px' }}>
                                <CustomRadio options={ListOne} value={value} onChange={onChange} />
                            </div>
                            <div>
                                <CustomRadio options={ListTwo} value={value} onChange={onChange} />
                            </div>
                        </DeskRadioWrapper>
                    </InnerWrap>
                }
                <ButtonWrapper>
                    <LeadFormBack onClick={goBack}>
                        Back
                    </LeadFormBack>
                    <LeadFormNext disabled={disabled} onClick={goNext}>
                        Next
                    </LeadFormNext>
                </ButtonWrapper>
            </ContentWrap>
        </Fragment>
    );
}

const ButtonWrapper = styled.div`
    width: 115%;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 992px){
        flex-direction: row;
    }
`;

const DeskRadioWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;
export default Q4TaxDebt;
